import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import './packages.css'
import { Switch } from '@headlessui/react';
import { BanknotesIcon, BoltIcon, CalendarIcon, CheckIcon, ClockIcon, CurrencyRupeeIcon, EyeIcon, PlusIcon, Squares2X2Icon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import HeadlessUIModalComponent from '../../../../shared-components/modal/HeadlessUIModal';
import { DELETE_ENTITY_STATUS, DISABLE_ENTITY_STATUS, ENABLE_ENTITY_STATUS, PLAN_TYPE_PACKAGE, PLAN_VALIDITY, defaultPackageList, defaultServiceList } from '../../../../../commonServices/commonDataService';
import { frequencyList } from '../../../../../commonServices/commonDataService';
import { connect } from 'react-redux';
import { plansPackagesApiService } from '../../../../../commonServices/apiService';
import {
    PACKAGE_CREATED_SUCCESSFULLY,
    PACKAGE_COULDNT_CREATED,
    PACKAGE_DELETED_SUCCESSFULLY,
    PACKAGE_COULDNT_DELETED,
    PACKAGE_STATUS_UPDATED_SUCCESSFULLY,
    PACKAGE_STATUS_COULDNT_UPDATED,
} from '../../../../../commonServices/messageConstants';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { quillModules } from '../../../../../commonServices/quillModules';


function PackageList(props) {
    console.log('package page props', props)
    const location = useLocation();
    let params = useParams();

    const [allTreatmentServicesList, setAllTreatmentServicesList] = useState([])

    // Add Package States
    const [showAddPackage, setShowAddPackage] = useState(false);
    const [addPackageName, setAddPackageName] = useState('');
    const [addPackageShortDescription, setAddPackageShortDescription] = useState('');
    const [addPackageLongDescription, setAddPackageLongDescription] = useState('');
    const [addPackageService, setAddPackageService] = useState(0);
    const [addPackageFrequency, setAddPackageFrequency] = useState(frequencyList[0].frequency);
    const [addPackageValidity, setAddPackageValidity] = useState(PLAN_VALIDITY[0].dayCountValue);

    const [addPackagePrice, setAddPackagePrice] = useState('0');
    const [addPackageDuration, setAddPackageDuration] = useState('0');

    // View Package States
    const [showViewPackage, setShowViewPackage] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);

    // Delete Package States
    const [showDeletePackage, setShowDeletePackage] = useState(false);
    const [selectedPackageToDelete, setSelectedPackageToDelete] = useState(null);

    // Display states
    const [packages, setPackages] = useState([]);

    const handlePackageStatusChange = (id, newStatus) => {
        console.log(id, newStatus);
        const body = {
            id: id,
            status: newStatus == true ? ENABLE_ENTITY_STATUS : DISABLE_ENTITY_STATUS
        }
        plansPackagesApiService.changePlanStatus(body).then(response => {
            console.log(response)
            if (response.data.status == true) {
                setPackages(packages.map(pack => pack.id === id ? { ...pack, status: newStatus } : pack))
                toast.success(PACKAGE_STATUS_UPDATED_SUCCESSFULLY)
            } else {
                toast.error(PACKAGE_STATUS_COULDNT_UPDATED)
            }
        }).catch(err => console.error(err))
    }



    useEffect(() => {
        getAllServices()
    }, [props])

    useEffect(() => {
        getAllPackages()
    }, [])


    const getAllPackages = () => {
        const body = {
            packageType: PLAN_TYPE_PACKAGE
        }
        plansPackagesApiService.getAllplans(body).then(response => {
            console.log('all currentPlans', response.data.data)
            if (response.data.status == true) {
                const responseData = response.data.data
                setPackages(responseData)
                // setCurrentPlans(responseData)
                // console.log(responseData[0].id)
                // setAddPlanService(responseData[0].id)
                // console.log(addPlanService)
            }
        }).catch(err => console.error(err))
    }

    const getAllServices = () => {
        setAllTreatmentServicesList(props.allServices.allServicesData)
        // console.log(props.allServices.allServicesData)
        setAddPackageService(props.allServices.allServicesData[0]?.id)
        // setServiceFilterList([...new Set(props.allServices.allServicesData.map(service => service.treatmentServiceName))])
    }

    const PackageCardComponent = ({ pack }) => {
        return (
            <div className='bg-indigo-100 flex flex-col justify-between px-4 rounded-lg shadow-md shadow-indigo-200 border border-indigo-300'>
                <div>
                    <div className='text-center my-2'>
                        <h3 className='text-xl font-semibold'>{pack.packageName}</h3>
                    </div>
                    <div className='text-left'>
                        <p className='text-gray-900 flex items-center gap-3'><Squares2X2Icon className='w-5 h-5' /> {pack.treatmentServiceName}</p>
                        <p className='text-gray-900 flex items-center gap-3'><CurrencyRupeeIcon className='w-5 h-5' /> ₹ {pack.packageAmount}</p>
                        <p className='text-gray-900 flex items-center gap-3'><CalendarIcon className='w-5 h-5' /> {pack.packageDuration} Days.</p>
                        <p className='text-gray-900 flex items-center gap-3'><ClockIcon className='w-5 h-5' /> {pack.packageTherapyTime} Mins.</p>
                        <p className='text-gray-900 flex items-center gap-3'><BoltIcon className='w-5 h-5' /> {pack.therapyFrequency} X</p>
                        <p className='text-sm my-3 text-gray-600'>{pack.shortDescription}</p>
                    </div>
                </div>
                <div className='my-2 flex justify-between items-center'>
                    <Switch
                        checked={pack.status}
                        onChange={(newStatus) => { handlePackageStatusChange(pack.id, newStatus) }}
                        className={`border ${pack.status ? 'bg-green-300 border-green-500' : 'bg-red-300 border-red-500'} relative inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
                    >
                        <span
                            aria-hidden="true"
                            className={`${pack.status ? 'translate-x-6 bg-green-700/90' : 'translate-x-0 bg-red-700/90'} pointer-events-none text-white inline-block h-5 w-5 transform mt-[1px] ml-[1px] rounded-full shadow-lg ring-0 transition-all duration-200 ease-in-out`}
                        >
                            {pack.status
                                ?
                                <CheckIcon className='w-4 absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2' />
                                :
                                <XMarkIcon className='w-4 absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2' />
                            }
                        </span>
                    </Switch>
                    <div className='flex gap-2'>
                        <button
                            onClick={() => {
                                setSelectedPackage(pack);
                                setShowViewPackage(true);
                            }}
                            className='bg-indigo-200 block items-center gap-1 font-medium text-[15px] p-2 rounded-full hover:bg-indigo-300 text-indigo-900 focus:outline-none w-full'
                        >
                            <EyeIcon className='w-5 h-5 mx-auto' />
                        </button>
                        <button
                            onClick={() => {
                                setSelectedPackageToDelete(pack);
                                setShowDeletePackage(true);
                            }}
                            className='bg-red-200 font-medium p-2 rounded-full hover:bg-red-300 text-red-900 focus:outline-none w-full'
                        >
                            <TrashIcon className='w-5 h-5 mx-auto' />
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    const addPackageModalBody = (
        <div className='mt-4'>
            <div className='mb-2'>
                <label htmlFor='packageName' className='block text-sm font-medium text-gray-700'>Package Name</label>
                <input
                    type='text'
                    name='packageName'
                    id='packageName'
                    required
                    value={addPackageName}
                    onChange={(e) => { setAddPackageName(e.target.value) }}
                    className='mt-[2px] block w-full px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                />
            </div>
            <div className='mb-2'>
                <label htmlFor='packageShortDescription' className='block text-sm font-medium text-gray-700'>Package Short Description</label>
                <input
                    type='text'
                    name='packageShortDescription'
                    id='packageShortDescription'
                    required
                    value={addPackageShortDescription}
                    onChange={(e) => { setAddPackageShortDescription(e.target.value) }}
                    className='mt-[2px] block w-full px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                />
            </div>
            <div className='mb-2'>
                <label htmlFor='packageLongDescription' className='block text-sm font-medium text-gray-700'>Package Long Description</label>
                <ReactQuill
                    name='packageLongDescription'
                    id='packageLongDescription'
                    theme="snow"
                    modules={quillModules}
                    value={addPackageLongDescription}
                    onChange={(value) => { setAddPackageLongDescription(value) }}
                    className='mt-[2px] block w-full border-2 border-gray-300 shadow-sm focus-within:ring-indigo-500 focus-within:border-indigo-500 sm:text-sm focus:outline-none'
                />
            </div>
            <div className='mb-2'>
                <label htmlFor='packageServiceName' className='block text-sm font-medium text-gray-700'>Package Service</label>
                <select
                    name="packageServiceName"
                    id="packageServiceName"
                    className='mt-[2px] block w-full px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                    value={addPackageService}
                    onChange={(e) => { setAddPackageService(parseInt(e.target.value)) }}
                >
                    {allTreatmentServicesList.map((service) => (
                        <option key={service.id} value={service.id}>{service.treatmentServiceName}</option>
                    ))}
                </select>
            </div>
            <div className='mb-2'>
                <label htmlFor='packageFrequency' className='block text-sm font-medium text-gray-700'>Package Frequency</label>
                <select
                    name="packageFrequency"
                    id="packageFrequency"
                    className='mt-[2px] block w-full px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                    value={addPackageFrequency}
                    onChange={(e) => { setAddPackageFrequency(e.target.value) }}
                >
                    {frequencyList.map((freq) => (
                        <option key={freq.id} value={freq.frequency}>{freq.frequency}</option>
                    ))}
                </select>
            </div>
            {/* package validity in days */}
            <div className='mb-2'>
                <label htmlFor='packageValidity' className='block text-sm font-medium text-gray-700'>Package Validity</label>
                <select
                    name="packageValidity"
                    id="packageValidity"
                    className='mt-[2px] block w-full px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                    value={addPackageValidity}
                    onChange={(e) => { setAddPackageValidity(e.target.value) }}
                >
                    {PLAN_VALIDITY.map((validity) => (
                        <option key={validity.id} value={validity.dayCountValue}>{validity.name}</option>
                    ))}
                </select>
            </div>


            <div className='flex gap-2'>
                <div className='mb-2 w-1/2'>
                    <label htmlFor='packagePrice' className='block text-sm font-medium text-gray-700'>Package Price</label>
                    <input
                        type='text'
                        name='packagePrice'
                        id='packagePrice'
                        required
                        value={addPackagePrice}
                        onChange={(e) => { setAddPackagePrice(e.target.value.replace(/[^0-9]/, '')) }}
                        className='mt-[2px] block w-full px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                    />
                </div>


                <div className='mb-2 w-1/2'>
                    <label htmlFor='packageDuration' className='block text-sm font-medium text-gray-700'>Treatment Duration in Minutes</label>
                    <input
                        type='text'
                        name='packageDuration'
                        id='packageDuration'
                        required
                        value={addPackageDuration}
                        onChange={(e) => { setAddPackageDuration(e.target.value.replace(/[^0-9]/, '')) }}
                        className='mt-[2px] block w-full px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                    />
                </div>
            </div>
        </div>
    )

    const handleAddPackage = (e) => {
        e.preventDefault();
        if (addPackageName.trim() === '' || addPackageShortDescription.trim() === '' || addPackageLongDescription.trim() === '' || addPackagePrice.trim() === '' || addPackageDuration.trim() === '') {
            alert('Please fill all the fields')
            return;
        }

        console.log(addPackageFrequency)
        const serviceName = allTreatmentServicesList.find(x => x.id == addPackageService)
        const body = {
            packageType: PLAN_TYPE_PACKAGE,
            treatmentServiceID: parseInt(addPackageService),
            packageName: addPackageName,
            packageAmount: parseFloat(addPackagePrice),
            packageDuration: addPackageValidity,
            shortDescription: addPackageShortDescription,
            longDescription: addPackageLongDescription,
            therapyFrequency: parseInt(addPackageFrequency),
            packageTherapyTime: parseInt(addPackageDuration),
            treatmentServiceName: serviceName.treatmentServiceName

        }
        console.log(body)
        plansPackagesApiService.createPlanPackage(body).then(response => {
            if (response.data.status == true) {
                console.log(response.data)
                const responseData = response.data.data
                setPackages([...packages, {
                    treatmentServiceID: responseData.id,
                    packageName: responseData.packageName,
                    shortDescription: responseData.shortDescription,
                    longDescription: responseData.longDescription,
                    treatmentServiceName: responseData.treatmentServiceName,
                    therapyFrequency: responseData.therapyFrequency,
                    status: responseData.status,
                    packageAmount: responseData.packageAmount,
                    packageTherapyTime: responseData.packageTherapyTime

                }])
                setShowAddPackage(false);
                setAddPackageName('');
                setAddPackageShortDescription('');
                setAddPackageLongDescription('');
                setAddPackageService(props.allServices.allServicesData[0]?.id)
                setAddPackageFrequency(frequencyList[0].frequency);
                setAddPackagePrice('0');
                setAddPackageDuration('0');
                toast.success(PACKAGE_CREATED_SUCCESSFULLY)

            } else {
                setShowAddPackage(false);
                toast.error(PACKAGE_COULDNT_CREATED)
            }
        }).catch(err => console.error(err))






        // console.log(addPackageName, addPackageShortDescription, addPackageLongDescription, parseFloat(addPackagePrice), addPackageDuration);
        // let maxId = Math.max(...packages.map(pack => pack.id));
        // setPackages([...packages, {
        //     id: maxId + 1,
        //     name: addPackageName,
        //     shortDescription: addPackageShortDescription,
        //     longDescription: addPackageLongDescription,
        //     service: addPackageService,
        //     frequency: parseInt(addPackageFrequency),
        //     status: false,
        //     price: parseInt(addPackagePrice),
        //     duration: parseInt(addPackageDuration)
        // }])
        // setShowAddPackage(false);
        // setAddPackageName('');
        // setAddPackageShortDescription('');
        // setAddPackageLongDescription('');
        // setAddPackageService(defaultServiceList[0].title);
        // setAddPackageFrequency(frequencyList[0].frequency);
        // setAddPackagePrice('0');
        // setAddPackageDuration('0');
    }

    const handleDeletePackage = (id) => {
        const body = {
            id: id,
            status: DELETE_ENTITY_STATUS
        }
        plansPackagesApiService.changePlanStatus(body).then(response => {
            console.log(response)
            if (response.data.status == true) {
                setPackages(packages.filter(pack => pack.id !== id))
                setShowDeletePackage(false)
                toast.success(PACKAGE_DELETED_SUCCESSFULLY)
            } else {
                setShowDeletePackage(false)
                toast.error(PACKAGE_COULDNT_DELETED)
            }
        }).catch(err => console.error(err))
    }

    return (
        <div className='w-full p-5'>
            {/* Add Package Modal */}
            <HeadlessUIModalComponent
                displayState={showAddPackage}
                setDisplayState={setShowAddPackage}
                headingChildren={<span>Create New Package</span>}
                bodyChildren={addPackageModalBody}
                footerChildren={
                    <div className='flex gap-2'>
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-indigo-100 hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                            onClick={handleAddPackage}
                        >
                            Create
                        </button>
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-200 px-4 py-2 text-sm font-medium text-indigo-900 hover:bg-indigo-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                            onClick={() => { setShowAddPackage(false) }}
                        >
                            Cancel
                        </button>
                    </div>
                }
            />

            {/* View Package Modal */}
            <HeadlessUIModalComponent
                displayState={showViewPackage}
                setDisplayState={setShowViewPackage}
                headingChildren={<>
                    <BanknotesIcon className='w-8 h-8' />
                    <span>{selectedPackage?.packageName}</span>
                </>}
                bodyChildren={
                    <div className='p-4'>
                        <div className='text-left mb-5'>
                            <p className='text-gray-900 flex items-center gap-3'><Squares2X2Icon className='w-5 h-5' /> {selectedPackage?.treatmentServiceName}</p>
                            <p className='text-gray-900 flex items-center gap-3'><CurrencyRupeeIcon className='w-5 h-5' /> ₹ {selectedPackage?.packageAmount}</p>
                            <p className='text-gray-900 flex items-center gap-3'><CalendarIcon className='w-5 h-5' /> {selectedPackage?.packageDuration} Days.</p>
                            <p className='text-gray-900 flex items-center gap-3'><ClockIcon className='w-5 h-5' /> {selectedPackage?.packageTherapyTime} Mins.</p>
                            <p className='text-gray-900 flex items-center gap-3'><BoltIcon className='w-5 h-5' /> {selectedPackage?.therapyFrequency} X</p>
                        </div>
                        <div className='text-left my-2'>
                            <p className='text-gray-900 mb-3'><span className='font-medium text-gray-950'>Short Desc.:</span> <br />{selectedPackage?.shortDescription}</p>
                            <p className='text-gray-900 mb-3'><span className='font-medium text-gray-950'>Long Desc.: </span><br /><span className='no-tailwind' dangerouslySetInnerHTML={{ __html: selectedPackage?.longDescription }} /></p>
                        </div>
                    </div>
                }
                footerChildren={
                    <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-indigo-200 px-4 py-2 text-sm font-medium text-indigo-900 hover:bg-indigo-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                        onClick={() => { setShowViewPackage(false) }}
                    >
                        Close
                    </button>
                }
            />

            {/* Delete Package Modal */}
            <HeadlessUIModalComponent
                displayState={showDeletePackage}
                setDisplayState={setShowDeletePackage}
                headingChildren={<span>Delete Package</span>}
                bodyChildren={<span>Are you sure you want to delete {selectedPackageToDelete?.name}?</span>}
                footerChildren={
                    <div className='flex gap-2'>
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-red-100 hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                            onClick={() => { handleDeletePackage(selectedPackageToDelete.id) }}
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-red-200 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                            onClick={() => { setShowDeletePackage(false) }}
                        >
                            Cancel
                        </button>
                    </div>
                }
            />

            {/* Packages List */}
            <div className='flex items-center justify-between' >
                <h1 className='text-2xl'>Packages</h1>
                <button
                    onClick={() => { setShowAddPackage(true) }}
                    className='bg-indigo-300 flex items-center gap-1 font-medium text-[15px] py-2 px-4 rounded-lg hover:bg-indigo-400 text-indigo-900 focus:outline-none'
                >
                    <PlusIcon className='h-5 w-5' /> Add New Package
                </button>
            </div>
            <div className='mt-5 w-10/12 mx-auto'>
                <div>
                    {
                        packages.length === 0 ?
                            <p className='mt-10 text-xl text-gray-700'>No packages to display{packages.length ? ' with selected filter' : ''}!</p>
                            :
                            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5'>
                                {packages.map((pack, index) => (
                                    <PackageCardComponent key={index} pack={pack} />
                                ))}
                            </div>
                    }

                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    allServices: state.allServices
})

export default connect(mapStateToProps, null)(PackageList);