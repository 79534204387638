import React, { useEffect, useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import HeadlessUIModalComponent from '../../../../../shared-components/modal/HeadlessUIModal';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

const MemberAllPurchases = () => {
    const params = useParams();
    const location = useLocation();

    const [showPurchaseDetails, setShowPurchaseDetails] = useState(false);
    const [selectedPurchase, setSelectedPurchase] = useState(null);

    const [userPurchases, setUserPurchases] = useState([]);

    useEffect(() => {
        const userPurchases = location.state?.purchases;
        if (userPurchases) {
            setUserPurchases(userPurchases);
        }
    }, [location.state?.purchases])

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const getFormattedDate = (date) => {
        const d = new Date(date);
        return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`
    }

    const trClassList = {
        'PLAN': 'bg-amber-200',
        'PACKAGE': 'bg-cyan-200',
        'MEMBERSHIP': 'bg-indigo-200',
        'CLASS': 'bg-pink-200'
    }

    const allPurchasesList = (
        <div className="mt-6 flex flex-col">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden border border-gray-50 md:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-50">
                            <thead className="bg-gray-800">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-4 py-3.5 text-left text-sm font-semibold text-gray-200"
                                    >
                                        Name
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-12 py-3.5 text-left text-sm font-semibold text-gray-200"
                                    >
                                        Start Date
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-12 py-3.5 text-left text-sm font-semibold text-gray-200"
                                    >
                                        End Date
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-12 py-3.5 text-center text-sm font-semibold text-gray-200"
                                    >
                                        Sessions Attended
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-4 py-3.5 text-center text-sm font-semibold text-gray-200"
                                    >
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-50 bg-gray-300/50">
                                {userPurchases.map((purchase) => (
                                    <tr key={purchase.id} className={trClassList[purchase.bookingType]}>
                                        <td className="whitespace-nowrap px-4 py-4">
                                            <div className="flex items-center gap-2">
                                                <div className="block text-left">
                                                    <div className="text-sm font-medium text-gray-900">{JSON.parse(purchase.bookingService)?.packageName || JSON.parse(purchase.bookingService)?.className}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap text-left px-12 py-4">
                                            <div className="text-sm text-gray-900">{getFormattedDate(purchase.bookingDate)}</div>
                                        </td>
                                        <td className="whitespace-nowrap text-left px-12 py-4">
                                            <div className="text-sm text-gray-900">{(purchase.bookingType === "PACKAGE" || purchase.bookingType === "MEMBERSHIP") ? `${getFormattedDate(purchase.expiryDate)}` : 'NA'}</div>
                                        </td>
                                        <td className="whitespace-nowrap px-4 py-4">
                                            <div className="text-sm text-center text-gray-900">{(purchase.bookingType === "CLASS") ? `NA` : `$${parseFloat(purchase.totalAmount)}`}</div>
                                        </td>
                                        <td className="whitespace-nowrap px-4 py-4 text-center text-sm font-medium flex gap-1">
                                            <button onClick={() => {
                                                setShowPurchaseDetails(true);
                                                setSelectedPurchase(purchase)
                                            }} title='Edit/View Admin' className="rounded-full bg-white/50 mx-auto px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-white/75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <div>
            {/* Purchase Details Modal */}
            <HeadlessUIModalComponent
                displayState={showPurchaseDetails}
                setDisplayState={setShowPurchaseDetails}
                headingChildren={<h1 className='text-xl font-semibold'>Purchase Details</h1>}
                bodyChildren={
                    <div className='flex flex-col gap-3'>
                        <div className='flex items-center gap-2'>
                            <label className='font-semibold'>Package Type:</label>
                            <span
                                className={`py-0.5 px-3 font-medium rounded-full ${selectedPurchase?.bookingType === "PLAN" ? 'bg-amber-200 text-amber-900' : selectedPurchase?.bookingType === "PACKAGE" ? 'bg-cyan-200 text-cyan-900' : selectedPurchase?.bookingType === "MEMBERSHIP" ? 'bg-indigo-200 text-indigo-900' : selectedPurchase?.bookingType === "CLASS" ? 'bg-pink-200 text-pink-900' : ''}`}
                            >
                                {selectedPurchase?.bookingType}
                            </span>
                        </div>
                        <div className='flex gap-2'>
                            <label className='font-semibold'>Package Name:</label>
                            <span>{selectedPurchase?.bookingService && (JSON.parse(selectedPurchase?.bookingService)?.packageName || JSON.parse(selectedPurchase?.bookingService)?.className)}</span>
                        </div>
                        <div className='flex gap-2'>
                            <label className='font-semibold'>Start Date:</label>
                            <span>{getFormattedDate(selectedPurchase?.bookingDate)}</span>
                        </div>
                        <div className='flex gap-2'>
                            <label className='font-semibold'>End Date:</label>
                            <span>{(selectedPurchase?.bookingType === "PACKAGE" || selectedPurchase?.bookingType === "MEMBERSHIP") ? `${getFormattedDate(selectedPurchase?.expiryDate)}` : 'NA'}</span>
                        </div>
                        <div className='flex gap-2'>
                            <label className='font-semibold'>Total Amount:</label>
                            <span>{(selectedPurchase?.bookingType === "CLASS") ? `NA` : `$${parseFloat(selectedPurchase?.totalAmount)}`}</span>
                        </div>
                    </div>
                }
                footerChildren={
                    <div className='flex gap-2'>
                        <button
                            onClick={() => setShowPurchaseDetails(false)}
                            className='bg-indigo-200 px-4 py-2 rounded text-indigo-900 hover:bg-indigo-300'
                        >
                            Close
                        </button>
                    </div>
                }
            />
            <Link
                to={`/memberDetails/${params.id}`}
                state={{ user: location.state?.user }}
                className='flex items-center gap-2 w-fit py-1 px-3 rounded-full bg-indigo-200 text-indigo-900 hover:bg-indigo-300 font-semibold mt-5 ml-5'
            >
                <ArrowLeftIcon className='w-5 h-5' /> Back
            </Link>
            <h1 className='text-2xl my-5 font-semibold'>Member #{params.id} All Purchases</h1>
            <div className='w-11/12 mx-auto'>
                {allPurchasesList}
            </div>
        </div>
    );
};

export default MemberAllPurchases;